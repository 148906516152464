<template>
  <section>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div>
            <title-plus
              :title="
                $t(
                  'components.vehicleDetailsManagement.diveIntoTab.repairLogs.headline'
                )
              "
              :hide-plus="true"
            />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>
      <FSTable
        :fst-id="fstId"
        :headers="getTableHeaders"
        :endpoint="getEndpoint"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>
        <template v-slot:default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem :text="item._source.created_at" :date="true" />
                <FSTableRowItem>
                  <XStatus
                    :text="getItemStatus(item._source.status)"
                    :variant="getItemVariant(item._source.status)"
                    profile="repair-log"
                  />
                </FSTableRowItem>
                <FSTableRowItem>
                  <span class="text-blue-800">{{
                    item._source.part_name
                  }}</span>
                  :
                  <span class="text-orange-900">{{
                    item._source.sub_part_name
                  }}</span>
                  :
                  <span
                    v-if="item._source.status === 'REPAIRED'"
                    class="text-gray-500"
                    >{{ item._source.repair_title }}</span
                  >
                  <span v-else class="text-gray-500">--</span>
                </FSTableRowItem>
                <FSTableRowItem :text="item._source.repairman_email" />
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow
                :key="`fs-table-row-${itemIndex}`"
                text-fallback-always
              >
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem :text="item._source.created_at" :date="true" />
                <FSTableRowItem>
                  <span class="text-blue-800">{{
                    item._source.part_name
                  }}</span>
                  :
                  <span class="text-orange-900">{{
                    item._source.sub_part_name
                  }}</span>
                  :
                  <span
                    v-if="item._source.status === 'REPAIRED'"
                    class="text-gray-500"
                    >{{ item._source.repair_title }}</span
                  >
                  <span v-else class="text-gray-500">--</span>
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.vehicleDetailsManagement.diveIntoTab.repairLogs.table.columns.status'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <XStatus
                        :text="getItemStatus(item._source.status)"
                        :variant="getItemVariant(item._source.status)"
                        profile="repair-log"
                      />
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.vehicleDetailsManagement.diveIntoTab.repairLogs.table.columns.repairedBy'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item._source.repairman_email }}
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </section>
</template>

<script>
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import XStatus from '@/components/badge/XStatus'
import { useEndpoints } from '@/composables'
import { getFormattedDateTime } from '@/utils/datetime'
export default {
  name: 'ViewVehicleRepairLogs',
  props: {
    fstId: {
      type: String,
      required: true,
      default: 'vehicle-repair-logs',
    },
  },

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    XStatus,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      tableHeaders: [
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.repairLogs.table.columns.time'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.repairLogs.table.columns.status'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.repairLogs.table.columns.title'
          ),
          width: '38%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.repairLogs.table.columns.repairedBy'
          ),
          width: '22%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.repairLogs.table.columns.time'
          ),
          width: '40%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.repairLogs.table.columns.title'
          ),
          width: '60%',
          sort: null,
        },
      ],
      opened: [],
      filterOptions: [
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Repaired', value: 'REPAIRED' },
            { text: 'Repair Failed', value: 'REPAIR_FAILED' },
            { text: 'Repair Not Required', value: 'REPAIR_NOT_NEEDED' },
          ],
          title: 'Status',
        },
      ],
    }
  },

  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getEndpoint() {
      return useEndpoints.operationPrimer.repairAction.logs(
        this.$route.params.id
      )
    },
  },

  async created() {},
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onApplyFilterDateRange,
    getFormattedDateTime,
    getItemVariant(data) {
      if (data === 'REPAIRED') return 'green'
      if (data === 'REPAIR_FAILED') return 'red'
      if (data === 'REPAIR_NOT_NEEDED') return 'gray'
      return 'gray'
    },
    getItemStatus(data) {
      if (data === 'REPAIRED') return 'Repaired'
      if (data === 'REPAIR_FAILED') return 'Not Repaired'
      if (data === 'REPAIR_NOT_NEEDED') return 'Repair Not Needed'
      return data
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },

    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
